<template>
    <div class="content_body" v-loading="loading">
        <div class="nav_header ">
            <el-row>
                <el-col :span="20">
                    <el-form :inline="true"  size="small">
                        <el-form-item label="搜索">
                            <el-input v-model="name" placeholder="请输入编码或名称查询"></el-input>
                        </el-form-item>
                        <el-form-item label="职务">
                            <el-select v-model="value" placeholder="请选择" clearable >
                                <el-option
                                        v-for="item in dutyList"
                                        :key="item.pattribute"
                                        :label="item.name"
                                        :value="item.pattribute">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="在职状态">
                            <el-select v-model="value" placeholder="请选择" clearable >
                                <el-option
                                        v-for="item in dutyList"
                                        :key="item.pattribute"
                                        :label="item.name"
                                        :value="item.pattribute">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属单位">
                            <el-select v-model="value" placeholder="请选择" clearable >
                                <el-option
                                        v-for="item in dutyList"
                                        :key="item.pattribute"
                                        :label="item.name"
                                        :value="item.pattribute">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" >查询</el-button>
                        </el-form-item>

                    </el-form>
                </el-col>
                <el-col :span="4" class="text_right">
                    <el-button type="primary" size="small" @click="showAddDialog">新增</el-button>
                </el-col>
            </el-row>
        </div>

        <div class="martp_10">
            <el-table size="small" :data="tableData">
                <el-table-column prop="name" label="编号"></el-table-column>
                <el-table-column prop="number" label="员工姓名"></el-table-column>
                <el-table-column prop="number" label="职务"></el-table-column>
                <el-table-column prop="number" label="在职状态"></el-table-column>
                <el-table-column prop="number" label="所属单位"></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small"   @click="showAddDialog(scope.row)">编辑</el-button>
                        <el-button type="primary" size="small"   @click="showEditDialog(scope.row)">跳转</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pad_15 text_right">
                <el-pagination background
                               v-if="paginations.total > 0"
                               @current-change="handleCurrentChange"
                               :current-page.sync="paginations.page"
                               :page-size="paginations.page_size"
                               :layout="paginations.layout"
                               :total="paginations.total"
                ></el-pagination>
            </div>
        </div>

        <!--弹窗-->
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
        >
            <div>
                <div class="marbm_15" v-for="(item,index) in 2" :key="index">
                    <div class="marbm_10 font_weight_600">PC{{item}}</div>
                    <div>
                        <el-checkbox-group v-model="checkList">
                            <el-checkbox class="marbm_10" label="复选框 A" ></el-checkbox>
                            <el-checkbox class="marbm_10" label="复选框 B" ></el-checkbox>
                            <el-checkbox class="marbm_10" label="复选框 C" ></el-checkbox>
                            <el-checkbox class="marbm_10" label="禁用" ></el-checkbox>
                            <el-checkbox class="marbm_10" label="选中且禁用" ></el-checkbox>
                            <el-checkbox class="marbm_10" label="复选框 B"></el-checkbox>
                            <el-checkbox class="marbm_10" label="复选框 C"></el-checkbox>
                            <el-checkbox class="marbm_10" label="禁用"></el-checkbox>
                            <el-checkbox class="marbm_10" label="选中且禁用"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" size="small" @click="dialogVisible = false">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    var Enumerable = require('linq');
    export default {
        data() {
            return {
                loading: false,
                dialogVisible: false,
                dutyList: [],
                tableData: [
                    {name: "测试", number: 30}
                ],
                value: "",
                name: "",
                checkList:[],
                //需要给分页组件传的信息
                paginations: {
                    page: 1, // 当前位于哪页
                    total: 5, // 总数
                    page_size: 10, // 1页显示多少条
                    layout: "total, prev, pager, next,jumper" // 翻页属性
                },
            }
        },
        methods: {
            // 数据显示
            handleSearch: function () {
                let that = this;
                that.paginations.page = 1;
                that.search();
            },
            // 数据显示
            search: function () {
                let that = this;
                that.loading = true;
            },
            // 上下分页
            handleCurrentChange(page) {
                var that = this;
                that.paginations.page = page;
                that.search();
            },
            // 新增
            showAddDialog: function () {
                var that = this;
                that.dialogVisible = true;
            },
            // 跳转
            showEditDialog: function () {
              var that = this;
               that.$router.push('Templet2')
            }
        }
    }
</script>

<style scoped lang="scss">
    .nav_header {
        padding: 15px 15px 0 15px;
    }
</style>